import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

const styles = {
  container: {
    //border: '1px solid #e0e0e0',
    //borderRadius: '6px',
    margin: "10px 0px 10px 0px",
    padding: "10px 0px 10px 0px",
  },
  textfield: {
    width: "100%",
  },
};

export default function MultilineTextInputComponent(props) {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={styles.container}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" style={{ fontWeight: 500 }} gutterBottom>
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          value={props.fieldvalue === null ? "" : props.fieldvalue}
          name={props.fieldname}
          multiline
          rows={10}
          variant="outlined"
          style={styles.textfield}
          onChange={(e) => props.onChangeEvent(e, "descrizione")}
        />
      </Grid>
    </Grid>
  );
}

MultilineTextInputComponent.propTypes = {
  label: PropTypes.string.isRequired,
  fieldname: PropTypes.string.isRequired,
  fieldvalue: PropTypes.string,
  onChangeEvent: PropTypes.func.isRequired,
};
