import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress } from "@material-ui/core";

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          {" "}
          <CloseIcon />{" "}
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinnerContainer: {
    position: "relative",
  },
}));

export default function TwoActionsAlertDialogForFileUpload(props) {
  const classes = useStyles();

  if (!props.visible) return null;
  return (
    <Dialog open={props.visible} onClose={props.onIndietroClicked}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
        <br></br>
        <DialogContentText>{props.message2}</DialogContentText>
        <br></br>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onIndietroClicked();
          }}
          color="primary"
          style={{ color: "red" }}
        >
          Indietro
        </Button>
        <Button onClick={props.onOkClicked} style={{ color: "green" }}>
          Ok
        </Button>
      </DialogActions>
      {props.loading && (
        <div className={classes.overlay}>
          <CircularProgress size={50} />
        </div>
      )}
    </Dialog>
  );
}

TwoActionsAlertDialogForFileUpload.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onIndietroClicked: PropTypes.func.isRequired,
  onOkClicked: PropTypes.func.isRequired,
};
