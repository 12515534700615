import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, Paper, TextField } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, HighlightOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  gridElement: {
    padding: "10px 0px",
  },
  paper: {
    padding: "12px",
    boxShadow:
      "2px 2px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 2px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
  },
  image: {
    height: 146,
    margin: "auto",
    display: "block",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const ImageAttachmentComponent = ({
  image,
  index,
  onChangeField,
  onMove,
  onRemoveAttachment,
  totalAttachments,
  urlPath,
}) => {
  const URL = process.env.REACT_APP_BACKEND_ENDPOINT;
  const classes = useStyles();

  const [titolo, setTitolo] = useState(image.titolo || "");
  const [note, setNote] = useState(image.note || "");

  const isFirst = index === 0;
  const isLast = index === totalAttachments - 1;

  // Per aggiornare gli stati quando le props cambiano (quando la prop image cambia)
  useEffect(() => {
    setTitolo(image.titolo || "");
    setNote(image.note || "");
  }, [image]);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className={classes.gridElement}
    >
      <Paper className={classes.paper}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <div className={classes.image}>
              <img
                className={classes.img}
                alt="componente immagine"
                src={`${URL}${urlPath}${image.id}`}
              />
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <div>
              <TextField
                label="Titolo"
                value={titolo}
                name="titolo"
                multiline
                onChange={(e) => setTitolo(e.target.value)}
                onBlur={() => onChangeField(titolo, index, "titolo")}
              />
              <TextField
                label="Note"
                value={note}
                name="note"
                multiline
                onChange={(e) => setNote(e.target.value)}
                onBlur={() => onChangeField(note, index, "note")}
              />
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <div>
              <IconButton
                color="primary"
                size="small"
                onClick={() => onRemoveAttachment(image.id)}
              >
                <HighlightOff />
              </IconButton>
            </div>
            <div>
              <IconButton
                color="primary"
                size="small"
                disabled={isFirst}
                onClick={() => onMove(index, index - 1)}
              >
                <ArrowUpward style={{ color: isFirst ? "gray" : "inherit" }} />
              </IconButton>
            </div>
            <div>
              <IconButton
                color="primary"
                size="small"
                disabled={isLast}
                onClick={() => onMove(index, index + 1)}
              >
                <ArrowDownward style={{ color: isLast ? "gray" : "inherit" }} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ImageAttachmentComponent.propTypes = {
  image: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  totalAttachments: PropTypes.number,
  urlPath: PropTypes.string.isRequired,
};

export default ImageAttachmentComponent;
