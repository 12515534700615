import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import theme from "../theme.js";
import { useTheme } from "@material-ui/core";

export default function ActionButton(props) {
  const themeToUse = useTheme();
  return (
    <Button
      type="button"
      onClick={props.onClick}
      variant="contained"
      size={props.size ? props.size : "medium"}
      disabled={props.disabled}
      style={{
        color: props.disabled
          ? theme.palette.text.disabled
          : props.grayVersion
          ? theme.palette.text.disabled
          : themeToUse.palette.text.main,
        margin: "10px",
        backgroundColor: props.disabled
          ? theme.palette.disabled.main
          : props.grayVersion
          ? theme.palette.secondary.other
          : props.secondaryButton
          ? themeToUse.palette.secondary.main
          : themeToUse.palette.primary.main,
      }}
    >
      {props.label}
    </Button>
  );
}

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  grayVersion: PropTypes.bool,
};
