import { retrieveToken } from "../storage";
import { wrapFetch, ApiError, wrapFetchWithDetailedErrors } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getParagrafi = (page, size, sortby, id, manualeId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/paragrafi";
  let query =
    "?" +
    "page=" +
    page +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC";
  if (id !== null) {
    query = query + "&id.equals=" + id;
  }
  if (manualeId !== null) {
    query = query + "&manualeId.equals=" + manualeId;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
export const getParagrafiManualePersonalizzato = (manualeId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/paragrafi";
  let query =
    "?manualeId.equals=" +
    manualeId +
    "&page=0&size=1000&sort=numeroParagrafo,ASC";
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
export const putModificaParagrafiManualePersonalizzato = (body) => {
  const token = retrieveToken();
  const PATH = "/api/paragrafi/personalizzati";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: responsabile sicurezza non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(body),
    }),
    errorFunction
  );
};
export const getContenutoParagrafo = (id) => {
  const token = retrieveToken();
  const PATH = "/api/paragrafi/personalizzati/contenuto/" + id;
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const postUploadImmaginiSottoparagrafi = (file) => {
  const PATH = "/api/paragrafi/sottoparagrafi/immagini/";
  const URL = BASE_URL + PATH;
  let formData = new FormData();
  formData.append("file", file);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 413) {
      message =
        "Caricamento fallito: il formato dell'immagine o la dimensione dell'immagine non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      body: formData,
    }),
    errorFunction
  );
};

export const updateSottoparagrafi = (body) => {
  const token = retrieveToken();
  const PATH = "/api/paragrafi/personalizzati/contenuto";
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: responsabile sicurezza non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(body),
    }),
    errorFunction
  );
};
export const getImmaginiSottoparagrafo = (id) => {
  const token = retrieveToken();
  const PATH = "/api/paragrafi/sottoparagrafi/immagini/" + id;
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const postAggiuntaPDFForParagrafo = (formData) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = `/api/files`;
  const URL = BASE_URL + PATH;

  const errorFunction = (status, serverMessage = null) => {
    let message = serverMessage;
    if (status === 403) {
      message =
        message ||
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message =
        message || "Salvataggio non riuscito: i dati non sono corretti.";
    } else if (status === 409) {
      message = message || '"Attenzione, esiste già un brand con questo nome"';
    }
    return new ApiError(status, message);
  };
  return wrapFetchWithDetailedErrors(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        // "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
      credentials: "include",
      body: formData,
    }),
    errorFunction
  );
};
export const getPDFForParagrafo = (id) => {
  const token = retrieveToken();
  const PATH = "/api/files?id=" + id;
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const postAggiuntaParagrafoManualeConParti = (body) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/paragrafi";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message = "Operazione non riuscita: dati non corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(body),
    }),
    errorFunction
  );
};
export const deleteParagrafoFromManualeConParti = (id) => {
  const token = retrieveToken();
  const PATH = "/api/paragrafi/" + id;
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: responsabile sicurezza non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "DELETE",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
export const postAggiuntaSottoparagrafoManualeConParti = (body) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/paragrafi/personalizzati/sottoparagrafi";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      message = "Operazione non riuscita: dati non corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(body),
    }),
    errorFunction
  );
};
export const deleteSottoparagrafoFromManualeConParti = (id) => {
  const token = retrieveToken();
  const PATH = "/api/paragrafi/personalizzati/sottoparagrafi/" + id;
  const URL = BASE_URL + PATH;
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: responsabile sicurezza non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "DELETE",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
