import React from "react";
import { Container, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import UploadFileComponent from "../../components/UploadFileComponent";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px",
    maxWidth: "700px",
    padding: 0,
  },
  title: {
    margin: "20px 0px",
  },
  description1: {
    margin: "0px 0px 5px 0px",
  },
  description2: {
    margin: "5px 0px 10px 0px",
  },
};

export default function FileUploader(props) {
  let title = "";
  let description1 = "";
  let description2 = "";

  if (props.accept.includes("image")) {
    title = "Carica una nuova immagine per la procedura";
    description1 = "I file caricati devono essere in formato PNG.";
    description2 = "La dimensione massima consentita è 500KB.";
  } else if (props.accept.includes("pdf")) {
    title = "Carica un nuovo documento PDF per la procedura";
    description1 = "I file caricati devono essere in formato PDF.";
    description2 = "La dimensione massima consentita è 1MB.";
  }

  return (
    <Container maxWidth="md" style={styles.root}>
      <Typography variant="h6" style={styles.title}>
        {title}
      </Typography>
      <Typography style={styles.description1}>{description1}</Typography>
      <Typography style={styles.description2}>{description2}</Typography>
      {/* <UploadImageComponent
        disabled={props.disabled}
        loading={props.loading}
        onSubmit={props.onUpload}
        accept={props.accept}
      /> */}
      <UploadFileComponent
        accept={props.accept}
        loading={props.loading}
        onSubmit={props.onUpload}
        onFileSelect={props.onFileSelect}
        uploadDisabled={props.uploadDisabled}
      />
    </Container>
  );
}

FileUploader.propTypes = {
  accept: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  uploadDisabled: PropTypes.bool.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};
