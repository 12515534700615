import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SpinnerComponent from "../../../components/SpinnerComponent";
import ActionButton from "../../../components/ActionButton";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    width: "100%",
  },
  fileName: {
    wordBreak: "break-word",
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const UploadFileComponent = ({
  accept,
  uploadDisabled,
  loading,
  onFileSelect,
  onSubmit,
}) => {
  const classes = useStyles();

  const [file, setFile] = useState(null);

  const selectFile = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      onFileSelect(e);
    } else {
      setFile(null);
      onFileSelect(e);
    }
  };

  return (
    <div className={classes.mainWrapper}>
      <div>
        <input
          id={`${accept}-fileInput`}
          type="file"
          name="file"
          accept={accept}
          onChange={selectFile}
          style={{ display: "none" }}
        />
        <label htmlFor={`${accept}-fileInput`}>
          <Button
            variant="contained"
            component="span"
            className={classes.button}
          >{`Seleziona file ${
            accept.includes("image") ? "PNG" : "PDF"
          }`}</Button>
        </label>
      </div>
      <Typography variant="subtitle2" className={classes.fileName}>
        {!file || uploadDisabled ? "Nessun file selezionato" : file.name}
      </Typography>
      {loading ? (
        <SpinnerComponent size={30} />
      ) : (
        <ActionButton
          label="Carica file"
          onClick={(e) => onSubmit(file, e)}
          grayVersion={false}
          disabled={uploadDisabled}
        />
      )}
    </div>
  );
};

UploadFileComponent.propTypes = {
  accept: PropTypes.string.isRequired,
  uploadDisabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UploadFileComponent;
