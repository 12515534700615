import React from "react";
import {
  Checkbox,
  Container,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";
import OneLineTextInputComponent from "../manualecomponents/components/OneLineTextInputComponent";
import MultilineTextInputComponent from "../manualecomponents/components/MultilineTextInputComponent";
import {
  getAttrezzatureWithManualeIdAndTipologia,
  getAttrezzatureWithOnlyManualeId,
  getTipologiaStrumentazione,
} from "../../../utils/api/locali_e_attrezzature_api";
import FileUploader from "../manualecomponents/dettaglio_procedura/FileUploader.js";
import AttachmentList from "../components/AttachmentList.js";
import { getProceduraHaccpTramiteId } from "../../../utils/api/procedure_api";
import {
  updateProceduraHaccp,
  uploadProceduraImage,
} from "../../../utils/api/componenti_paragrafi_api";
import ErrorDialog from "../../../components/ErrorDialog";
import { getCategorieControlli } from "../../../utils/api/categorie_controlli_api";
import SpinnerComponent from "../../../components/SpinnerComponent";
import { Edit } from "@material-ui/icons";
import ModalSelezioneLimiti from "./ModalSelezioneLimiti";
import ModalSelezioneAssociazione from "./ModalSelezioneAssociazione";
import ModalSelezioneOrario from "./ModalSelezioneOrario";
import { getReparti } from "../../../utils/api/reparti";
import IndietroButtonColorFul from "../../../components/IndietroButtonColorful";
import ModalListaAttrezzatura from "./ModalListaAttrezzatura";
import { clearToken } from "../../../utils/storage";
import { getFlagControlliSemplificatiManualeFromId } from "../../../utils/api/modelli_and_manuali_api";
import PropTypes from "prop-types";
import {
  getPDFForParagrafo,
  postAggiuntaPDFForParagrafo,
} from "../../../utils/api/paragrafi_api.js";

const styles = (theme) => ({
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px 10px",
    minHeight: "100vh",
  },
  typo: {
    align: "center",
  },
  uploadWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  controlliWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

class DettaglioProceduraNuovoManuale extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      records: [],
      loading: true,
      procedura: null,
      immaginiProcedure: [],
      newImageFile: null,
      newPdfFile: null,
      errorDialogVisible: false,
      errorDialogMessage: "",
      errorDialogTitle: "",
      isOrarioIconDisabled: false,
      uploadingImage: false,
      isLimitiModalOpen: false,
      cadenzaPassata: "GIORNALIERA",
      isAssociazioneModalOpen: false,
      isAttrezzatureModalOpen: false,
      attrezzatureToRead: null,
      attrezzature: [],
      reparti: [],
      limitiMode: "CONTROLLO_TEMPERATURE",
      controlloSelezionato: null,
      orarioMode: "Other",
      categorieControlli: [],
      associazioneMode: "Reparti",
      tipologieElementi: [],
      imageUploadDisabled: true,
      pdfUploadDisabled: true,
      totalPages: 0,
      isOrarioModalOpen: false,
      indexToUpdate: 0,
      readyToSend: true,
      edit: false,
      controlliSemplificati: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    let a = this.generateProceduraColumns();
    this.setState({
      columns: a,
    });
    this.checkControlliSemplificati();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    const { path } = this.props.theme;
    window.setTimeout(function () {
      window.location.href = "/" + path;
    }, 4000);
  };

  openErrorDialog = (errorMessage) => {
    this.setState({
      errorDialogTitle: "Errore",
      errorDialogVisible: true,
      errorDialogMessage: errorMessage,
    });
  };

  openSuccessDialog = (successMessage) => {
    this.setState({
      errorDialogTitle: "Operazione effettuata",
      errorDialogVisible: true,
      errorDialogMessage: successMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  addImage = (id, index) => {
    let image = {
      id: id,
      titolo: "",
      note: "",
      posizione: index,
    };
    return image;
  };

  addControlloToTable = () => {
    let recordsToSave = [...this.state.records];
    let repartiIds = [];
    this.state.reparti.map((item) => repartiIds.push(item.id));
    let newControllo = {
      id: null,
      idParagrafo: this.props.idParagrafo,
      categorieControlli: this.state.categorieControlli,
      tipologieElementi: this.state.tipologieElementi,
      elementiOsservati: "",
      selectedCadenza: "ALTRO",
      selectedTipologiaElemento: 0,
      temperaturaMinima: null,
      temperaturaMassima: null,
      temperaturaConsigliata: null,
      TPM_Minimo: null,
      TPM_Massimo: null,
      TPM_Standard: null,
      selectedCategoriaControllo: "CONTROLLO_GENERICO",
      selectedElementoOsservato: null,
      isOrarioIconDisabled: false,
      nonConformita: [],
      controlliNonConformitaSemplificato: {
        id: null,
        idControlloConformita: null,
        azioniCorrettive: [],
      },
      limiti: "",
      attrezzature: [],
      reparti: repartiIds,
      Cadenze: ["GIORNALIERA", "SETTIMANALE", "MENSILE", "ANNUALE", "ALTRO"],
      datiCadenze: [],
      altraCadenza: null,
      Bloccato: false,
      Periodica: false,
    };
    recordsToSave.push(newControllo);
    this.setState({
      records: recordsToSave,
    });
  };

  setCadenzeToControllo = (cadenze) => {
    let arrayOfControlli = this.state.records[this.state.indexToUpdate];
    arrayOfControlli.datiCadenze = cadenze;
    this.setState({
      isOrarioModalOpen: false,
    });
  };

  addAttrezzatureOrRepartiToControllo = (recordsToAdd, mode) => {
    if (mode === "Reparto") {
      let arrayOfRepartiToAssociate = [];
      recordsToAdd.forEach((item) => {
        if (item.checked) {
          arrayOfRepartiToAssociate.push(item.idReparto);
        }
      });
      let array = [...this.state.records];
      array[this.state.indexToUpdate].reparti = arrayOfRepartiToAssociate;
      array[this.state.indexToUpdate].attrezzature = [];
    } else if (mode === "Attrezzatura") {
      let arrayOfRepartiToAssociate = [];
      let arrayOfAttrezzatureToAssociate = [];
      recordsToAdd.forEach((item) => {
        if (item.checked) {
          arrayOfRepartiToAssociate.push(item.repartoId);
          arrayOfAttrezzatureToAssociate.push(item.id);
        }
      });
      getAttrezzatureWithManualeIdAndTipologia(
        this.props.idManuale,
        this.state.records[this.state.indexToUpdate].selectedTipologiaElemento,
        0,
        10000,
        "indiceOrdinamento,ASC"
      )
        .then((response) => {
          let readyToAssociate = true;

          if (readyToAssociate === true) {
            let array = [...this.state.records];
            array[this.state.indexToUpdate].reparti = arrayOfRepartiToAssociate;
            array[this.state.indexToUpdate].attrezzature =
              arrayOfAttrezzatureToAssociate;
            this.setState({
              readyToSend: true,
            });
          } else {
            this.openErrorDialog(
              "Attenzione, una o più attrezzature non sono associate alla tipologia desiderata"
            );
            this.setState({
              readyToSend: false,
            });
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.openErrorDialog(error.message);
          }
        });
    }
    this.setState({
      isAssociazioneModalOpen: false,
    });
  };

  handleChangeProceduraField = (event, field) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      procedura: {
        ...prevState.procedura,
        [field]: value,
      },
      notSaved: true,
    }));
  };

  uploadImage = (file) => {
    this.setState({
      uploadingImage: true,
      imageUploadDisabled: true,
    });
    uploadProceduraImage(file)
      .then((result) => {
        let id = result;
        this.confirmImageRegistration(id);
      })
      .catch((error) => {
        this.setState({
          loading: false,
          uploadingImage: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  uploadPdf = () => {
    const { newPdfFile } = this.state;
    const formData = new FormData();
    formData.append("file", newPdfFile);

    this.setState({
      uploadingImage: true,
      pdfUploadDisabled: true,
    });

    postAggiuntaPDFForParagrafo(formData)
      .then((response) => {
        this.confirmPdfRegistration(response.id);
      })
      .catch((error) => {
        this.setState({
          loading: false,
          uploadingImage: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  handleImageFileSelection = (event) => {
    const imageFile = event.target.files[0];

    if (imageFile.type !== "image/png") {
      this.openErrorDialog(
        "Attenzione, possono essere caricate solo immagini in formato PNG."
      );
      return;
    }
    if (imageFile.size > 500000) {
      this.openErrorDialog(
        "Attenzione, la dimensione dell'immagine caricata è maggiore 500kB."
      );
      return;
    }

    this.setState({
      newImageFile: imageFile,
      imageUploadDisabled: false,
    });
  };

  handlePdfFileSelection = (event) => {
    const pdfFile = event.target.files[0];

    if (!pdfFile) return;

    if (pdfFile.type !== "application/pdf") {
      this.openErrorDialog(
        "Attenzione, possono essere caricati solo documenti in formato PDF."
      );
      return;
    }

    if (pdfFile.size > 1000000) {
      this.openErrorDialog(
        "Attenzione, la dimensione del documento caricato è maggiore di 1MB."
      );
      return;
    }

    this.setState({
      newPdfFile: pdfFile,
      pdfUploadDisabled: false,
    });
  };

  confirmImageRegistration = (id) => {
    this.setState((prevState) => {
      const newImage = this.addImage(id, prevState.immaginiProcedure.length);
      const updatedImmaginiProcedure = [
        ...prevState.immaginiProcedure,
        newImage,
      ];
      const updatedProcedura = {
        ...prevState.procedura,
        immagini: updatedImmaginiProcedure,
      };

      return {
        newImageFile: null,
        immaginiProcedure: updatedImmaginiProcedure,
        procedura: updatedProcedura,
        uploadingImage: false,
        changesNotSaved: true,
        disableSubmitButton: false,
        notSaved: true,
      };
    });
  };

  confirmPdfRegistration = (pdfId) => {
    const { newPdfFile } = this.state;
    this.setState((prevState) => {
      const pdfObject = {
        id: pdfId,
        note: "",
        posizione: prevState.immaginiProcedure.length,
        titolo: newPdfFile.name,
        type: "PDF",
      };

      const updatedImmaginiProcedure = [
        ...prevState.immaginiProcedure,
        pdfObject,
      ];
      const updatedProcedura = {
        ...prevState.procedura,
        immagini: updatedImmaginiProcedure,
      };

      return {
        newPdfFile: null,
        immaginiProcedure: updatedImmaginiProcedure,
        procedura: updatedProcedura,
        pdfUploadDisabled: true,
        uploadingImage: false,
        changesNotSaved: true,
        disableSubmitButton: false,
        notSaved: true,
      };
    });
  };

  deleteAttachment = (id) => {
    this.setState((prevState) => {
      const updatedImmaginiProcedure = prevState.immaginiProcedure
        .filter((item) => item.id !== id)
        .map((item, index) => ({ ...item, posizione: index }));

      const updatedProcedura = {
        ...prevState.procedura,
        immagini: updatedImmaginiProcedure,
      };

      return {
        immaginiProcedure: updatedImmaginiProcedure,
        procedura: updatedProcedura,
        changesNotSaved: true,
        disableSubmitButton: false,
        notSaved: true,
      };
    });
  };

  changeImageField = (value, index, field) => {
    this.setState((prevState) => {
      const updatedImmaginiProcedure = prevState.immaginiProcedure.map(
        (img, i) => (i === index ? { ...img, [field]: value } : img)
      );
      const updatedProcedura = {
        ...prevState.procedura,
        immagini: updatedImmaginiProcedure,
      };

      return {
        procedura: updatedProcedura,
        immaginiProcedure: updatedImmaginiProcedure,
        notSaved: true,
      };
    });
  };

  moveComponent = (fromIndex, toIndex) => {
    this.setState((prevState) => {
      const immaginiProcedure = [...prevState.immaginiProcedure];

      if (
        fromIndex < 0 ||
        toIndex < 0 ||
        fromIndex >= immaginiProcedure.length ||
        toIndex >= immaginiProcedure.length
      ) {
        this.openErrorDialog(
          "Indici non validi per lo spostamento degli allegati."
        );
        return;
      }

      const [element] = immaginiProcedure.splice(fromIndex, 1); // Remove elemnent
      immaginiProcedure.splice(toIndex, 0, element); // Insert element at new index
      immaginiProcedure.forEach((a, index) => (a.posizione = index)); // Update position for each element

      return {
        immaginiProcedure,
        notSaved: true,
      };
    });
  };

  handlePdfView = (pdfId) => {
    if (!pdfId) {
      this.openErrorDialog(
        "ID PDF mancante. Non è possibile recuperare il documento."
      );
      return;
    }

    getPDFForParagrafo(pdfId)
      .then((response) => {
        if (response?.url) {
          window.open(response.url, "_blank");
        } else {
          this.openErrorDialog("URL PDF non disponibile.");
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  deleteRow = (cellInfo) => {
    let recordsToSave = [...this.state.records];
    recordsToSave.splice(cellInfo._index, 1);
    this.setState({
      records: recordsToSave,
    });
  };

  populateTable = (response) => {
    let controlliToAppend = response.controlliConformita;
    let arrayOfData = [];
    let isOrarioIconDisabled = false;
    controlliToAppend.forEach((item) => {
      let selectedTipologiaElemento = item.tipologiaStrumentazioneId;
      let selectedCadenza = item.cadenza;
      if (item.tipologiaStrumentazioneId === null) {
        selectedTipologiaElemento = 0;
      }
      if (item.cadenza === null && item.altraCadenza === null) {
        selectedCadenza = 0;
      } else if (item.cadenza === null) {
        selectedCadenza = "ALTRO";
        isOrarioIconDisabled = false;
      }
      let oldControllo = {
        id: item.id,
        idParagrafo: this.props.idParagrafo,
        categorieControlli: this.state.categorieControlli,
        tipologieElementi: this.state.tipologieElementi,
        elementiOsservati: item.descrizioneOsservabile,
        selectedCadenza: selectedCadenza,
        selectedTipologiaElemento: selectedTipologiaElemento,
        temperaturaMinima:
          item.temperaturaMinima === null
            ? null
            : parseFloat(item.temperaturaMinima),
        temperaturaMassima:
          item.temperaturaMassima === null
            ? null
            : parseFloat(item.temperaturaMassima),
        temperaturaConsigliata:
          item.temperaturaConsigliata === null
            ? null
            : parseFloat(item.temperaturaConsigliata),
        TPM_Minimo:
          item.valoreTPMMinimo === null
            ? null
            : parseFloat(item.valoreTPMMinimo),
        TPM_Massimo:
          item.valoreTPMMassimo === null
            ? null
            : parseFloat(item.valoreTPMMassimo),
        TPM_Standard:
          item.valoreTPMConsigliato === null
            ? null
            : parseFloat(item.valoreTPMConsigliato),
        selectedCategoriaControllo: item.categoriaControllo,
        selectedElementoOsservato: item.categoriaOsservabile,
        nonConformita: item.controlliNonConformita,
        controlliNonConformitaSemplificato:
          item.controlliNonConformitaSemplificato === null
            ? {
                id: null,
                idControlloConformita: null,
                azioniCorrettive: [],
              }
            : item.controlliNonConformitaSemplificato,
        limiti: item.limiti,
        isOrarioIconDisabled: isOrarioIconDisabled,
        attrezzature: item.attrezzature,
        reparti: item.reparti,
        Cadenze: ["GIORNALIERA", "SETTIMANALE", "MENSILE", "ANNUALE", "ALTRO"],
        datiCadenze: item.cadenze,
        altraCadenza: item.altraCadenza,
        Bloccato: item.bloccante,
        Periodica: item.periodico,
      };
      arrayOfData.push(oldControllo);
    });
    this.setState({
      records: arrayOfData,
    });
  };

  getAttrezzatureName = (attrezzature) => {
    let arrayOfNames = [];
    for (const attrezzatura of attrezzature) {
      this.state.attrezzature.forEach((item) => {
        if (item.id === attrezzatura) {
          arrayOfNames.push(item.nome);
        }
      });
    }
    return arrayOfNames;
  };

  checkControlliSemplificati = () => {
    //Aggiungere qui la GET del controllo così da trovarmi l'informazione direttamente al mounting del componente
    getFlagControlliSemplificatiManualeFromId(this.props.idManuale)
      .then((flag) => {
        if (this._isMounted) {
          this.setState(
            {
              controlliSemplificati: flag,
            },
            this.getProceduraData
          );
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  getProceduraData = () => {
    getProceduraHaccpTramiteId(this.props.id)
      .then((response) => {
        if (this._isMounted) {
          const immaginiOrdinate = response.immagini.sort(
            (a, b) => a.posizione - b.posizione
          );
          this.setState({
            procedura: response,
            immaginiProcedure: immaginiOrdinate,
            loading: false,
          });
        }
      })
      .then((response) => {
        getCategorieControlli()
          .then((response) => {
            let categorieControlli = response;
            if (this._isMounted) {
              this.setState({
                categorieControlli: categorieControlli,
              });
            }
          })
          .then((response) => {
            getTipologiaStrumentazione(
              this.props.idManuale,
              0,
              10000,
              "nome,ASC"
            )
              .then((response) => {
                let tipologieElementi = response;
                if (this._isMounted) {
                  this.setState({
                    tipologieElementi: tipologieElementi,
                  });
                }
              })
              .then((response) => {
                getAttrezzatureWithOnlyManualeId(
                  this.props.idManuale,
                  0,
                  10000,
                  "indiceOrdinamento,ASC"
                )
                  .then((response) => {
                    if (this._isMounted) {
                      this.setState({
                        attrezzature: response,
                      });
                    }
                  })
                  .then((response) => {
                    getReparti(this.props.idManuale, 0, 10000, "nome,asc").then(
                      (response) => {
                        if (this._isMounted) {
                          this.setState(
                            {
                              reparti: response,
                            },
                            () => {
                              this.populateTable(this.state.procedura);
                            }
                          );
                        }
                      }
                    );
                  });
              });
          });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  handleControlloSave = () => {
    if (this.state.readyToSend) {
      this.setState({
        loading: true,
      });
      let send = true;
      let errorMessage = "";
      let arrayToUse = [...this.state.records];
      let arrayOfControlliConformita = [];
      arrayToUse.forEach((item, index) => {
        let cadenzaToInsert = item.selectedCadenza;
        let altraCadenza = null;
        // Vincolo di due controlli giornalieri per 'controllo olio'
        if (item.selectedCategoriaControllo === "OLIO_FRIGGITRICE") {
          if (
            item.selectedCadenza !== "GIORNALIERA" ||
            item.datiCadenze.length !== 2
          ) {
            send = false;
            errorMessage =
              "Attenzione! Per il controllo olio friggitrice è necessario specificare DUE CADENZE GIORNALIERE: una mattutina e una pomeridiana.";
          }
        }
        if (!item.Periodica) {
          if (
            item.selectedCadenza !== "GIORNALIERA" &&
            item.selectedCadenza !== "SETTIMANALE" &&
            item.selectedCadenza !== "MENSILE" &&
            item.selectedCadenza !== "ANNUALE"
          ) {
            cadenzaToInsert = null;
            if (item.altraCadenza !== null) altraCadenza = item.altraCadenza;
            else {
              altraCadenza = "inserire cadenza";
            }
            item.datiCadenze = [];
          } else {
            if (item.datiCadenze.length === 0) {
              send = false;
              errorMessage =
                "Per controlli con cadenza giornaliera, settimanale, mensile o annuale è necessario indicare almeno un orario.";
            }
          }
        } else {
          if (
            item.selectedCadenza !== "GIORNALIERA" &&
            item.selectedCadenza !== "SETTIMANALE" &&
            item.selectedCadenza !== "MENSILE" &&
            item.selectedCadenza !== "ANNUALE"
          ) {
            cadenzaToInsert = null;
            if (item.altraCadenza !== null) altraCadenza = item.altraCadenza;
            else {
              altraCadenza = null;
            }
          } else {
            if (item.datiCadenze.length === 0) {
              let record = {};
              if (item.selectedCadenza === "GIORNALIERA") {
                record.orario = "08:00:00";
                record.giornoSettimana = null;
                record.mese = null;
                record.giornoMese = null;
                record.schedaId = null;
                record.controlloConformitaId = item.id;
                record.id = null;
              } else if (item.selectedCadenza === "SETTIMANALE") {
                record.orario = "08:00:00";
                record.giornoSettimana = "MONDAY";
                record.mese = null;
                record.giornoMese = null;
                record.schedaId = null;
                record.controlloConformitaId = item.id;
                record.id = null;
              } else if (item.selectedCadenza === "MENSILE") {
                record.orario = "08:00:00";
                record.giornoSettimana = null;
                record.mese = null;
                record.giornoMese = 1;
                record.schedaId = null;
                record.controlloConformitaId = item.id;
                record.id = null;
              } else if (item.selectedCadenza === "ANNUALE") {
                record.orario = "08:00:00";
                record.giornoSettimana = null;
                record.mese = "JANUARY";
                record.giornoMese = 1;
                record.schedaId = null;
                record.controlloConformitaId = item.id;
                record.id = null;
              }
              item.datiCadenze.push(record);
            }
          }
        }
        let tipologiaId = item.selectedTipologiaElemento;
        let categoria = "STRUMENTAZIONE";
        let repartiToPass = item.reparti;
        if (tipologiaId === 0 || tipologiaId === null) {
          tipologiaId = null;
          categoria = "ALTRO";
        }
        let descrizione = item.elementiOsservati;
        if (categoria === "STRUMENTAZIONE") {
          descrizione = null;
          repartiToPass = [];
          // if (item.attrezzature.length === 0) {
          //   errorMessage =
          //     "E' necessario associare almeno un'attrezzatura per un controllo con categoriaOsservabile di tipo STRUMENTAZIONE.";
          //   send = false;
          // }
        } else {
          let arrayToUseToFill = [...this.state.reparti];
          arrayToUseToFill.forEach((item) => {
            if (item.repartoDefault) {
              repartiToPass.push(item.id);
            }
          });
          if (item.attrezzature.length > 0) {
            item.attrezzature = [];
          }
          if (repartiToPass.length === 0) {
            send = false;
            errorMessage =
              "E' necessario associare almeno un reparto per un controllo con categoriaOsservabile diverso da STRUMENTAZIONE";
          }
        }
        /*if (
          this.state.controlliSemplificati &&
          item.controlliNonConformitaSemplificato.azioniCorrettive.length === 0
        ) {
          send = false;
          errorMessage =
            "Attenzione, è necessario inserire almeno un'azione correttiva all'interno di un controllo di non conformità.";
        }*/
        let temperaturaMinima = null;
        let temperaturaMassima = null;
        let temperaturaConsigliata = null;
        let TPM_Minimo = null;
        let TPM_Massimo = null;
        let TPM_Standard = null;
        let categoriaControllo = item.selectedCategoriaControllo;
        if (
          categoriaControllo === "CONTROLLO_TEMPERATURE" ||
          categoriaControllo === "OLIO_FRIGGITRICE"
        ) {
          if (
            item.temperaturaMinima !== null &&
            item.temperaturaMinima !== ""
          ) {
            temperaturaMinima = parseFloat(item.temperaturaMinima);
          }
          if (
            item.temperaturaMassima !== null &&
            item.temperaturaMassima !== ""
          ) {
            temperaturaMassima = parseFloat(item.temperaturaMassima);
          }
          if (
            item.temperaturaConsigliata !== null &&
            item.temperaturaConsigliata !== ""
          ) {
            temperaturaConsigliata = parseFloat(item.temperaturaConsigliata);
          }
          if (temperaturaMinima > temperaturaMassima) {
            send = false;
            errorMessage =
              "Attenzione, la temperatura minima è maggiore della temperatura massima";
          }
          if (temperaturaMassima < temperaturaMinima) {
            send = false;
            errorMessage =
              "Attenzione, la temperatura massima è minore della temperatura minima";
          }
          if (
            temperaturaConsigliata &&
            (temperaturaConsigliata < temperaturaMinima ||
              temperaturaConsigliata > temperaturaMassima)
          ) {
            send = false;
            errorMessage =
              "Attenzione, valore della temperatura standard fuori dal range impostato!";
          }
          if (
            temperaturaMinima == null ||
            temperaturaMinima === "" ||
            temperaturaMassima == null ||
            temperaturaMassima === ""
          ) {
            send = false;
            errorMessage =
              "Attenzione, occorre inserire i valori minimi e massimi di temperatura prima di procedere!";
          }
        }
        if (
          categoriaControllo === "TPM" ||
          categoriaControllo === "OLIO_FRIGGITRICE"
        ) {
          if (item.TPM_Minimo !== null && item.TPM_Minimo !== "") {
            TPM_Minimo = parseFloat(item.TPM_Minimo);
          }
          if (item.TPM_Massimo !== null && item.TPM_Massimo !== "") {
            TPM_Massimo = parseFloat(item.TPM_Massimo);
          }
          if (TPM_Minimo > TPM_Massimo) {
            send = false;
            errorMessage =
              "Attenzione, il valore TPM minimo è maggiore del valore TPM massimo";
          }
          if (TPM_Massimo < TPM_Minimo) {
            send = false;
            errorMessage =
              "Attenzione, il valore del TPM massimo è minore del valore TPM minimo";
          }
          if (
            TPM_Minimo == null ||
            TPM_Minimo === "" ||
            TPM_Massimo == null ||
            TPM_Massimo === ""
          ) {
            send = false;
            errorMessage =
              "Attenzione, occorre inserire i valori minimi e massimi del TPM prima di procedere!";
          }
        }
        if (categoriaControllo === "TPM") {
          if (item.TPM_Standard !== null && item.TPM_Standard !== "") {
            TPM_Standard = parseFloat(item.TPM_Standard);
          }
          if (
            TPM_Standard &&
            (TPM_Standard < TPM_Minimo || TPM_Standard > TPM_Massimo)
          ) {
            send = false;
            errorMessage =
              "Attenzione, valore del TPM standard fuori dal range impostato!";
          }
          if (TPM_Standard == null || TPM_Standard === "") {
            send = false;
            errorMessage =
              "Attenzione, occorre inserire il valore standard del TPM prima di procedere!";
          }
        }
        let controlloToPush = {
          id: item.id,
          proceduraHaccpId: this.state.procedura.id,
          bloccante: item.Bloccato,
          categoriaControllo: categoriaControllo,
          cadenza: cadenzaToInsert,
          altraCadenza: altraCadenza,
          limiti: item.limiti,
          descrizioneOsservabile: descrizione,
          tipologiaStrumentazioneId: tipologiaId,
          reparti: repartiToPass,
          attrezzature: item.attrezzature,
          categoriaOsservabile: categoria,
          periodico: item.Periodica,
          cadenze: item.datiCadenze,
          controlliNonConformita: item.nonConformita,
          controlliNonConformitaSemplificato:
            item.controlliNonConformitaSemplificato,
          temperaturaMinima: temperaturaMinima,
          temperaturaMassima: temperaturaMassima,
          temperaturaConsigliata: temperaturaConsigliata,
          valoreTPMMinimo: TPM_Minimo,
          valoreTPMMassimo: TPM_Massimo,
          valoreTPMConsigliato: TPM_Standard,
        };
        arrayOfControlliConformita.push(controlloToPush);
      });
      let descrizioneProcedura = this.state.procedura.descrizione;
      if (descrizioneProcedura === null) {
        descrizioneProcedura = "";
      }
      let objToSave = {
        id: this.state.procedura.id,
        paragrafoId: parseInt(this.props.idParagrafo),
        parentComponentId: null,
        numeroComponente: this.state.procedura.numeroComponente,
        stato: this.state.procedura.stato,
        titolo: this.state.procedura.titolo,
        descrizione: descrizioneProcedura,
        punticontrollo: [],
        controlliConformita: arrayOfControlliConformita,
        immagini: this.state.immaginiProcedure,
      };
      if (send) {
        updateProceduraHaccp(objToSave)
          .then((response) => {
            this.getProceduraData();
            this.setState({
              edit: false,
              loading: false,
            });
            this.openSuccessDialog("Elemento salvato con successo");
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.openErrorDialog(error.message);
            }
            this.setState({
              loading: false,
            });
          });
      } else {
        this.openErrorDialog(errorMessage);
        this.setState({
          loading: false,
        });
      }
    } else {
      this.openErrorDialog(
        "Attenzione, uno dei dati inseriti è ancora inconsistente"
      );
    }
  };

  passLimiti = (limiti) => {
    const { records, indexToUpdate } = this.state;
    let updatedControllo = records;
    if (this.state.controlliSemplificati) {
      updatedControllo = {
        ...records[indexToUpdate],
        controlliNonConformitaSemplificato: limiti,
      };
    } else {
      updatedControllo = {
        ...records[indexToUpdate],
        nonConformita: limiti,
      };
    }
    this.setState({
      records: [
        ...records.slice(0, indexToUpdate),
        updatedControllo,
        ...records.slice(indexToUpdate + 1),
      ],
      isLimitiModalOpen: false,
    });
  };

  passLimitiTemperatura = (limiti, tempMin, tempMax, tempCons) => {
    let arrayOfControlli = [...this.state.records];

    if (!this.state.controlliSemplificati)
      arrayOfControlli[this.state.indexToUpdate].nonConformita = limiti;
    else
      arrayOfControlli[
        this.state.indexToUpdate
      ].controlliNonConformitaSemplificato = limiti;
    if (tempMin == null || tempMin === "") {
      this.openErrorDialog(
        "Attenzione, il valore della temperatura minima non è un numero"
      );
    } else if (tempMax == null || tempMax === "") {
      this.openErrorDialog(
        "Attenzione, il valore della temperatura massima non è un numero"
      );
    } else if (tempCons == null || tempCons === "") {
      this.openErrorDialog(
        "Attenzione, il valore della temperatura standard non è un numero"
      );
    } else {
      arrayOfControlli[this.state.indexToUpdate].temperaturaMinima = tempMin;
      arrayOfControlli[this.state.indexToUpdate].temperaturaMassima = tempMax;
      arrayOfControlli[this.state.indexToUpdate].temperaturaConsigliata =
        tempCons;
      this.setState({
        isLimitiModalOpen: false,
      });
    }
  };

  passLimitiTPM = (limiti, TPMMin, TPMMax, TPMCons) => {
    var arrayOfControlli = [...this.state.records];

    if (!this.state.controlliSemplificati)
      arrayOfControlli[this.state.indexToUpdate].nonConformita = limiti;
    else
      arrayOfControlli[
        this.state.indexToUpdate
      ].controlliNonConformitaSemplificato = limiti;
    if (TPMMin == null || TPMMin === "") {
      this.openErrorDialog("Attenzione, il valore TPM minimo non è un numero");
    } else if (TPMMax == null || TPMMax === "") {
      this.openErrorDialog("Attenzione, il valore TPM massimo non è un numero");
    } else if (TPMCons == null || TPMCons === "") {
      this.openErrorDialog(
        "Attenzione, il valore TPM standard non è un numero"
      );
    } else {
      arrayOfControlli[this.state.indexToUpdate].TPM_Minimo = TPMMin;
      arrayOfControlli[this.state.indexToUpdate].TPM_Massimo = TPMMax;
      arrayOfControlli[this.state.indexToUpdate].TPM_Standard = TPMCons;
      this.setState({
        isLimitiModalOpen: false,
      });
    }
  };

  passLimitiOlioFriggitrice = (
    limiti,
    TPMMin,
    TPMMax,
    tempMin,
    tempMax,
    tempCons
  ) => {
    const { records, indexToUpdate } = this.state;
    let updatedControllo = records;
    if (this.state.controlliSemplificati) {
      updatedControllo = {
        ...records[indexToUpdate],
        controlliNonConformitaSemplificato: limiti,
      };
    } else {
      updatedControllo = {
        ...records[indexToUpdate],
        nonConformita: limiti,
      };
    }
    if (TPMMin == null || TPMMin === "" || TPMMax == null || TPMMax === "") {
      this.openErrorDialog(
        `Attenzione, il valore TPM ${
          !TPMMin ? "minimo" : "massimo"
        } non è un numero`
      );
    } else if (
      tempMin == null ||
      tempMin === "" ||
      tempMax == null ||
      tempMax === "" ||
      tempCons == null ||
      tempCons === ""
    ) {
      this.openErrorDialog(
        `Attenzione, il valore della temperatura ${
          !tempMin ? "minima" : !tempMax ? "massima" : "standard"
        } non è un numero`
      );
    } else {
      updatedControllo.TPM_Minimo = TPMMin;
      updatedControllo.TPM_Massimo = TPMMax;
      updatedControllo.temperaturaMinima = tempMin;
      updatedControllo.temperaturaMassima = tempMax;
      updatedControllo.temperaturaConsigliata = tempCons;
      this.setState({
        records: [
          ...records.slice(0, indexToUpdate),
          updatedControllo,
          ...records.slice(indexToUpdate + 1),
        ],
        isLimitiModalOpen: false,
      });
    }
  };

  generateProceduraColumns = () => {
    return [
      {
        Header: "Categoria Controllo",
        id: "CategoriaControllo",
        width: 250,
        accessor: (d) => d,
        Cell: (props) => (
          <Select
            defaultValue={"CONTROLLO_GENERICO"}
            value={props.row.CategoriaControllo.selectedCategoriaControllo}
            onChange={(event) => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[props.row._index].selectedCategoriaControllo =
                event.target.value;
              if (event.target.value !== "TPM") {
                arrayOfRecords[props.row._index].TPM_Minimo = null;
                arrayOfRecords[props.row._index].TPM_Massimo = null;
                arrayOfRecords[props.row._index].TPM_Standard = null;
              }
              if (event.target.value !== "CONTROLLO_TEMPERATURA") {
                arrayOfRecords[props.row._index].temperaturaMassima = null;
                arrayOfRecords[props.row._index].temperaturaMinima = null;
                arrayOfRecords[props.row._index].temperaturaConsigliata = null;
              }
              this.setState(
                {
                  records: arrayOfRecords,
                  edit: true,
                },
                () => {}
              );
            }}
            style={{ marginTop: 10, width: "100%" }}
          >
            {props.row.CategoriaControllo.categorieControlli.map(
              (item, index) => {
                return (
                  <MenuItem key={index} value={item.name}>
                    {item.label}
                  </MenuItem>
                );
              }
            )}
          </Select>
        ),
      },
      {
        Header: "Tipologia elemento",
        id: "TipologiaElemento",
        width: 250,
        accessor: (d) => d,
        Cell: (props) => (
          <Select
            defaultValue={0}
            value={props.row.TipologiaElemento.selectedTipologiaElemento}
            onChange={(event) => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[props.row._index].attrezzature = [];
              arrayOfRecords[props.row._index].selectedTipologiaElemento =
                event.target.value;
              if (event.target.value === 0) {
                arrayOfRecords[props.row._index].descrizioneOsservabile = null;
                arrayOfRecords[props.row._index].elementiOsservati = "";
              } else {
                this.state.attrezzature.map(
                  (item, index) =>
                    (arrayOfRecords[props.row._index].elementiOsservati =
                      item.nome + " ")
                );
              }
              this.setState({
                records: arrayOfRecords,
                edit: true,
              });
            }}
            style={{ marginTop: 10, width: "100%" }}
          >
            <MenuItem value={0}>Altra</MenuItem>
            {props.row.TipologiaElemento.tipologieElementi.map(
              (item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.nome}
                  </MenuItem>
                );
              }
            )}
          </Select>
        ),
      },
      {
        Header: "Elemento Osservato",
        id: "ElementoOsservato",
        width: 250,
        accessor: (d) => d.elementiOsservati,
        Cell: (props) =>
          props.row.TipologiaElemento.selectedTipologiaElemento === 0 ? (
            <TextField
              inputProps={{ min: 0, style: { textAlign: "center" } }}
              value={props.row.ElementoOsservato}
              onInput={(event) => {
                let arrayToChange = [...this.state.records];
                arrayToChange[props.row._index].elementiOsservati =
                  event.target.value;
                const caret = event.target.selectionStart;
                const element = event.target;
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret;
                  element.selectionEnd = caret;
                });
                this.setState({
                  records: arrayToChange,
                  edit: true,
                });
              }}
              style={{
                marginTop: 10,
                width: "100%",
                paddingLeft: 20,
                paddingRight: 20,
              }}
            />
          ) : (
            <ActionButton
              label={"Mostra Attrezzature"}
              disabled={false}
              grayVersion={false}
              style={{ marginLeft: 10 }}
              onClick={() =>
                this.openAttrezzaturaModal(
                  props.row.TipologiaElemento.attrezzature
                )
              }
            />
          ),
      },
      {
        Header: "Limiti",
        id: "Limiti",
        width: 200,
        accessor: (d) => d.limiti,
        Cell: (props) => (
          <TextField
            onInput={(event) => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[props.row._index].limiti = event.target.value;
              const caret = event.target.selectionStart;
              const element = event.target;
              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });
              this.setState({
                records: arrayOfRecords,
                edit: true,
              });
            }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={props.row.Limiti}
            style={{
              marginTop: 10,
              width: "100%",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          />
        ),
      },
      {
        Header: "Cadenza",
        id: "Cadenza",
        width: 250,
        accessor: (d) => d,
        Cell: (props) => (
          <Select
            onChange={(event) => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[props.row._index].selectedCadenza =
                event.target.value;
              arrayOfRecords[props.row._index].datiCadenze = [];
              if (
                event.target.value !== "GIORNALIERA" &&
                event.target.value !== "SETTIMANALE" &&
                event.target.value !== "MENSILE" &&
                event.target.value !== "ANNUALE" &&
                event.target.value !== 0 &&
                event.target.value !== "ALTRO"
              ) {
                arrayOfRecords[props.row._index].isOrarioIconDisabled = true;
              } else {
                arrayOfRecords[props.row._index].isOrarioIconDisabled = false;
              }
              this.setState({
                edit: true,
                records: arrayOfRecords,
              });
            }}
            value={props.row.Cadenza.selectedCadenza}
            style={{ marginTop: 10, width: "100%" }}
          >
            {/*<MenuItem value={0}>Selezionare una cadenza</MenuItem>*/}
            {/*props.row.Cadenza.altraCadenza != null && <MenuItem value={props.row.Cadenza.altraCadenza}>{props.row.Cadenza.altraCadenza}</MenuItem>*/}
            {props.row.Cadenza.Cadenze.map((item, index) => {
              if (!props.row.Periodica.Periodica) {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              } else {
                if (
                  item === "GIORNALIERA" ||
                  item === "SETTIMANALE" ||
                  item === "MENSILE" ||
                  item === "ANNUALE"
                ) {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                }
              }

              return null;
            })}
          </Select>
        ),
      },
      {
        Header: "Periodica",
        id: "Periodica",
        width: 100,
        accessor: (d) => d,
        Cell: (props) => (
          <Checkbox
            onClick={() => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[props.row._index].Periodica =
                !arrayOfRecords[props.row._index].Periodica;
              this.setState({
                records: arrayOfRecords,
                edit: true,
              });
            }}
            disabled={
              props.row.Cadenza.selectedCadenza === 0 ||
              props.row.Cadenza.selectedCadenza === "ALTRO"
            }
            checked={props.row.Periodica.Periodica}
            style={{ marginTop: 10 }}
          />
        ),
      },
      {
        Header: "Bloccato",
        id: "Bloccato",
        width: 100,
        accessor: (d) => d.Bloccato,
        Cell: (props) => (
          <Checkbox
            onClick={() => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[props.row._index].Bloccato =
                !arrayOfRecords[props.row._index].Bloccato;
              this.setState({
                records: arrayOfRecords,
                edit: true,
              });
            }}
            checked={props.row.Bloccato}
            style={{ marginTop: 10 }}
          />
        ),
      },
      {
        Header: "Lista Orari",
        id: "ListaOrari",
        width: 100,
        accessor: (d) => d,
        Cell: (props) => (
          <IconButton
            disabled={
              props.row.ListaOrari.isOrarioIconDisabled ||
              props.row.Periodica.Periodica
            }
            onClick={() =>
              this.openOrarioModal(
                props.row.Cadenza.selectedCadenza,
                props.row.Cadenza,
                props.row._index
              )
            }
            size="small"
            style={{ margin: 10 }}
          >
            <Edit />
          </IconButton>
        ),
      },
      {
        Header: "Conformità e non conformità",
        id: "IsConformita",
        width: 100,
        accessor: (d) => d,
        Cell: (props) => (
          <IconButton
            onClick={() => {
              this.openLimitiModal(
                props.row.IsConformita,
                props.row._index,
                props.row.CategoriaControllo.selectedCategoriaControllo
              );
            }}
            size="small"
            style={{ margin: 10 }}
          >
            <Edit />
          </IconButton>
        ),
      },
      {
        Header: "Associazioni",
        id: "Associazioni",
        width: 100,
        accessor: (d) => d,
        Cell: (props) => (
          <IconButton
            size="small"
            onClick={() =>
              this.openAssociazioneModal(
                props.row.TipologiaElemento.selectedTipologiaElemento,
                props.row._index,
                props.row.Associazioni
              )
            }
            style={{ margin: 10 }}
          >
            <Edit />
          </IconButton>
        ),
      },
      {
        Header: "Elimina",
        id: "Elimina",
        width: 120,
        accessor: (d) => d,
        Cell: (props) => (
          <ActionButton
            disabled={false}
            grayVersion={false}
            label="Elimina"
            onClick={() => this.deleteRow(props.row)}
          />
        ),
      },
    ];
  };

  closeOrarioModal = () => {
    this.setState({
      isOrarioModalOpen: false,
    });
  };

  openOrarioModal = (cadenza, controllo, index) => {
    if (cadenza === 0 || cadenza === "ALTRO") {
      this.setState({
        orarioMode: "Other",
      });
    } else {
      this.setState({
        orarioMode: "Normal",
        cadenzaPassata: cadenza,
      });
    }
    this.setState({
      isOrarioModalOpen: true,
      controlloSelezionato: controllo,
      indexToUpdate: index,
    });
  };

  handleCadenza = (altraCadenza, index) => {
    let arrayOfRecords = [...this.state.records];
    if (altraCadenza !== "") {
      arrayOfRecords[index].altraCadenza = altraCadenza;
      this.setState({
        records: arrayOfRecords,
        isOrarioModalOpen: false,
      });
    } else {
      this.openErrorDialog(
        "Attenzione, bisogna inserire un valore di altra cadenza per salvarne il valore"
      );
    }
  };

  openLimitiModal = (controllo, index, mode) => {
    this.setState({
      limitiMode: mode,
      isLimitiModalOpen: true,
      controlloSelezionato: controllo,
      indexToUpdate: index,
    });
  };

  closeLimitiModal = () => {
    this.setState({
      isLimitiModalOpen: false,
    });
  };

  openAttrezzaturaModal = (attrezzature) => {
    let attrezzatureToPass = this.getAttrezzatureName(attrezzature);

    this.setState({
      isAttrezzatureModalOpen: true,
      attrezzatureToRead: attrezzatureToPass,
    });
  };

  closeAttrezzaturaModal = () => {
    this.setState({
      isAttrezzatureModalOpen: false,
      attrezzatureToRead: null,
    });
  };

  openAssociazioneModal = (tipologia, index, controlloSelezionato) => {
    if (tipologia === 0) {
      this.setState({
        associazioneMode: "Reparti",
      });
    } else {
      this.setState({
        associazioneMode: "Attrezzature",
      });
    }
    this.setState({
      isAssociazioneModalOpen: true,
      indexToUpdate: index,
      controlloSelezionato: controlloSelezionato,
    });
  };

  closeAssociazioneModal = () => {
    this.setState({
      isAssociazioneModalOpen: false,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.mainContainer}>
        {this.state.loading ? (
          <SpinnerComponent size={32} />
        ) : (
          <>
            <Container>
              <OneLineTextInputComponent
                label={"Titolo:"}
                fieldname={"titolo"}
                fieldvalue={this.state.procedura.titolo}
                onChangeEvent={this.handleChangeProceduraField}
              />
              <MultilineTextInputComponent
                label={"Descrizione procedura"}
                fieldname={"descrizione"}
                fieldvalue={this.state.procedura.descrizione}
                onChangeEvent={this.handleChangeProceduraField}
              />
              <div className={classes.uploadWrapper}>
                <FileUploader
                  uploadDisabled={this.state.imageUploadDisabled}
                  loading={this.state.uploadingImage}
                  onFileSelect={this.handleImageFileSelection}
                  onUpload={this.uploadImage}
                  accept="image/png"
                />
                <FileUploader
                  uploadDisabled={this.state.pdfUploadDisabled}
                  loading={this.state.uploadingImage}
                  onFileSelect={this.handlePdfFileSelection}
                  onUpload={this.uploadPdf}
                  accept="application/pdf"
                />
              </div>
              <AttachmentList
                loading={this.state.uploadingImage}
                attachments={this.state.immaginiProcedure}
                onRemoveAttachment={this.deleteAttachment}
                onMove={this.moveComponent}
                onChangeField={this.changeImageField}
                urlPath="/api/paragrafi/procedurehaccp/immagini/"
                title="Allegati caricati"
                onViewPdf={this.handlePdfView}
              />
            </Container>
            <div className={classes.controlliWrapper}>
              <Typography className={classes.typo} variant="h4">
                Controlli di Conformità
              </Typography>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ActionButton
                  onClick={this.addControlloToTable}
                  label="Aggiungi"
                  grayVersion={false}
                  disabled={false}
                />
              </div>
              <ReactTable
                filterable={false}
                resizable={true}
                enableEditing={true}
                showPageSizeOptions={false}
                showPageJump={true}
                defaultPageSize={10}
                style={{
                  width: "90vw",
                  alignSelf: "center",
                }}
                data={this.state.records}
                columns={this.state.columns}
                previousText="Precedente"
                nextText="Successivo"
                noDataText="Nessun record"
                pageText="Pagina"
                ofText="di"
                rowsText="righe"
                pageJumpText="Vai a pagina"
                rowsSelectorText="righe per pagina"
              />
            </div>

            <ErrorDialog
              open={this.state.errorDialogVisible}
              message={this.state.errorDialogMessage}
              onCloseButtonClicked={this.closeErrorDialog}
              title={this.state.errorDialogTitle}
            />
            {this.state.isOrarioModalOpen && (
              <ModalSelezioneOrario
                theme={this.props.theme}
                open={this.state.isOrarioModalOpen}
                onClose={this.closeOrarioModal}
                mode={this.state.orarioMode}
                addCadenza={this.handleCadenza}
                cadenza={this.state.cadenzaPassata}
                cadenze={this.state.controlloSelezionato.datiCadenze}
                indexToUpdate={this.state.indexToUpdate}
                setCadenzeToControllo={this.setCadenzeToControllo}
                controlloConformita={this.state.controlloSelezionato}
              />
            )}
            {this.state.isLimitiModalOpen && (
              <ModalSelezioneLimiti
                theme={this.props.theme}
                open={this.state.isLimitiModalOpen}
                onClose={this.closeLimitiModal}
                reparti={this.state.reparti}
                passLimiti={this.passLimiti}
                passLimitiTemperatura={this.passLimitiTemperatura}
                passLimitiTPM={this.passLimitiTPM}
                passLimitiOlioFriggitrice={this.passLimitiOlioFriggitrice}
                mode={this.state.limitiMode}
                controlloconformita={this.state.controlloSelezionato}
                manualeId={this.props.idManuale}
                controlliSemplificati={this.state.controlliSemplificati}
              />
            )}
            {this.state.isAssociazioneModalOpen && (
              <ModalSelezioneAssociazione
                open={this.state.isAssociazioneModalOpen}
                onClose={this.closeAssociazioneModal}
                mode={this.state.associazioneMode}
                reparti={this.state.reparti}
                attrezzature={this.state.attrezzature}
                controlloSelezionato={this.state.controlloSelezionato}
                saveValues={this.addAttrezzatureOrRepartiToControllo}
              />
            )}
            {this.state.isAttrezzatureModalOpen && (
              <ModalListaAttrezzatura
                open={this.state.isAttrezzatureModalOpen}
                onClose={this.closeAttrezzaturaModal}
                attrezzatureToRead={this.state.attrezzatureToRead}
              />
            )}
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <IndietroButtonColorFul
                secondaryButton
                alert={this.state.edit}
                isDisabled={false}
              />
              <ActionButton
                disabled={false}
                grayVersion={false}
                onClick={this.handleControlloSave}
                label="Salva"
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

DettaglioProceduraNuovoManuale.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  idManuale: PropTypes.string.isRequired,
  idParagrafo: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(DettaglioProceduraNuovoManuale);
