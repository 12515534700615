import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import {
  ArrowDownward,
  ArrowUpward,
  HighlightOff,
  Launch,
  PictureAsPdf,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  gridElement: {
    padding: "10px 0px",
  },
  paper: {
    padding: "12px",
    boxShadow:
      "2px 2px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 2px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
  },
  pdfIcon: {
    fontSize: 60,
    height: 146,
  },
  contentWrapper: {
    display: "grid",
    gridTemplateRows: "repeat(3, auto)",
    // justifyContent: "center",
    gap: theme.spacing(1),
  },
}));

const PdfAttachmentComponent = ({
  index,
  onMove,
  onRemoveAttachment,
  onViewPdf,
  pdfAttachment,
  totalAttachments,
}) => {
  const classes = useStyles();

  const isFirst = index === 0;
  const isLast = index === totalAttachments - 1;

  return (
    <Grid item xs={12} className={classes.gridElement}>
      <Paper className={classes.paper}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={4}>
            <PictureAsPdf color="primary" className={classes.pdfIcon} />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.contentWrapper}>
              <Typography>
                {pdfAttachment.titolo || "Nome allegato.pdf"}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Launch />}
                onClick={() => onViewPdf(pdfAttachment.id)}
                disabled={!pdfAttachment?.id}
              >
                Visualizza pdf
              </Button>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <IconButton
                color="primary"
                size="small"
                onClick={() => onRemoveAttachment(pdfAttachment.id)}
              >
                <HighlightOff />
              </IconButton>
            </div>
            <div>
              <IconButton
                color="primary"
                size="small"
                disabled={isFirst}
                onClick={() => onMove(index, index - 1)}
              >
                <ArrowUpward style={{ color: isFirst ? "gray" : "inherit" }} />
              </IconButton>
            </div>
            <div>
              <IconButton
                color="primary"
                size="small"
                disabled={isLast}
                onClick={() => onMove(index, index + 1)}
              >
                <ArrowDownward style={{ color: isLast ? "gray" : "inherit" }} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

PdfAttachmentComponent.propTypes = {
  index: PropTypes.number,
  onMove: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  onViewPdf: PropTypes.func.isRequired,
  pdfAttachment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    titolo: PropTypes.string,
  }),
  totalAttachments: PropTypes.number,
};

export default PdfAttachmentComponent;
