import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ChromePicker } from "react-color";
import ActionButton from "../../../components/ActionButton";
import BrandNameInput from "./BrandNameInput";
import BrandUrlField from "./BrandUrlField";
import ColorPickerToggle from "./ColorPickerToggle";
import LoghiTemplate from "./LoghiTemplate";
import LogoHeaderTemplate from "./LogoHeaderTemplate";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "50px",
    gap: "24px",
  },
  paper: {
    width: "890px",
    padding: "12px",
    boxShadow: theme.shadows[5],
  },
  pickerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: " center",
    margin: "30px 0",
    gap: "35px",
  },
  textWrapper: {
    marginTop: "30px",
  },
  text: {
    margin: "5px 0px ",
  },
  loghiWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  halfWidth: {
    flex: 1,
    minWidth: "420px",
  },
  buttonWrapper: {
    padding: "20px 0",
  },
}));

const DettagliWebApp = ({
  isEditMode,
  brandName,
  handleBrandNameChange,
  templateData,
  handlePathChange,
  loghiTemplate,
  uploadImage,
  logoLeftUrl,
  uploadLogoLeft,
  selectedColor,
  handleColorChange,
  handleBackClick,
  createOrUpdateBrand,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <BrandNameInput
        isEditMode={isEditMode}
        brandName={brandName}
        handleBrandNameChange={handleBrandNameChange}
      />
      <BrandUrlField
        url={templateData.path}
        onUrlChange={handlePathChange}
        isEditMode={isEditMode}
      />
      <div className={classes.textWrapper}>
        <Typography variant="h6" className={classes.text}>
          Logo del brand
        </Typography>
        <Typography className={classes.text}>
          Qui è possibile personalizzare il logo. La dimensione massima
          consentita è 150KB.
        </Typography>
        <Typography className={classes.text}>
          I file caricati devono essere in formato JPEG (o JPG) o PNG.
        </Typography>
        <Typography className={classes.text}>
          Per risultati ottimali si consiglia un rapporto d'aspetto compreso tra
          16:9 e 9:16.
        </Typography>
      </div>
      <div className={classes.loghiWrapper}>
        <div className={classes.halfWidth}>
          <LoghiTemplate loghiIds={loghiTemplate} onSubmit={uploadImage} />
        </div>
        <div className={classes.halfWidth}>
          <LogoHeaderTemplate
            logoHeader={logoLeftUrl}
            uploadLogoHeader={uploadLogoLeft}
          />
        </div>
      </div>
      <Paper className={classes.paper}>
        <div className={classes.pickerWrapper}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Colori del brand
          </Typography>
          <Typography>
            Seleziona i due colori di riferimento della tua attività
          </Typography>
          <ColorPickerToggle
            selectedColor={selectedColor}
            onColorChange={handleColorChange}
          />
        </div>
        <div className={classes.pickerWrapper}>
          <Typography>Seleziona il colore del testo</Typography>
          <ChromePicker
            color={selectedColor.text}
            onChangeComplete={(color) => handleColorChange("text", color)}
            disableAlpha
          />
        </div>
      </Paper>
      <div className={classes.buttonWrapper}>
        <ActionButton
          label="Indietro"
          secondaryButton
          onClick={handleBackClick}
          grayVersion={false}
          disabled={false}
        />
        <ActionButton
          label={isEditMode ? "Salva modifiche" : "Crea brand"}
          onClick={createOrUpdateBrand}
          grayVersion={false}
        />
      </div>
    </Box>
  );
};

DettagliWebApp.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  brandName: PropTypes.string.isRequired,
  handleBrandNameChange: PropTypes.func.isRequired,
  templateData: PropTypes.object.isRequired,
  handlePathChange: PropTypes.func.isRequired,
  loghiTemplate: PropTypes.array.isRequired,
  uploadImage: PropTypes.func.isRequired,
  logoLeftUrl: PropTypes.string.isRequired,
  uploadLogoLeft: PropTypes.func.isRequired,
  selectedColor: PropTypes.object.isRequired,
  handleColorChange: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  createOrUpdateBrand: PropTypes.func.isRequired,
};

export default DettagliWebApp;
