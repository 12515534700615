import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SpinnerComponent from "../../../components/SpinnerComponent";
import PdfAttachmentComponent from "./PdfAttachmentComponent";
import ImageAttachmentComponent from "./ImageAttachmentComponent";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    marginBottom: "20px",
    minHeight: "296px",
  },
  text: {
    margin: "5px 0px 10px 0px",
  },
  gridContainer: {
    paddingTop: "10px",
  },
}));

export default function AttachmentList(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.root}>
      {props.title && (
        <Typography variant="h6" className={classes.text}>
          {props.title}
        </Typography>
      )}
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.gridContainer}
      >
        {props.loading ? (
          <SpinnerComponent size={32} />
        ) : (
          props.attachments.map((item) => {
            return item.type !== "PDF" ? (
              <ImageAttachmentComponent
                key={item.id}
                image={item}
                index={item.posizione}
                onChangeField={props.onChangeField}
                onMove={props.onMove}
                onRemoveAttachment={props.onRemoveAttachment}
                totalAttachments={props.attachments.length}
                urlPath={props.urlPath}
              />
            ) : (
              <PdfAttachmentComponent
                key={item.id}
                pdfAttachment={item}
                index={item.posizione}
                onMove={props.onMove}
                onRemoveAttachment={props.onRemoveAttachment}
                totalAttachments={props.attachments.length}
                onViewPdf={props.onViewPdf}
              />
            );
          })
        )}
      </Grid>
    </Container>
  );
}

AttachmentList.propTypes = {
  loading: PropTypes.bool,
  attachments: PropTypes.array.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  onViewPdf: PropTypes.func,
  title: PropTypes.string,
  urlPath: PropTypes.string.isRequired,
};
