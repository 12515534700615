import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

const styles = {
  container: {
    //border: '1px solid #e0e0e0',
    //borderRadius: '6px',
    margin: "10px 0px 14px 0px",
    padding: "10px 0px 14px 0px",
  },
  textfield: {
    width: "100%",
  },
};

export default function OneLineTextInputComponent(props) {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={styles.container}
    >
      <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={8} lg={10} xl={10}>
        <TextField
          value={props.fieldvalue === null ? "" : props.fieldvalue}
          name={props.fieldname}
          style={styles.textfield}
          variant="outlined"
          onChange={(e) => props.onChangeEvent(e, "titolo")}
        />
      </Grid>
    </Grid>
  );
}

OneLineTextInputComponent.propTypes = {
  label: PropTypes.string.isRequired,
  fieldname: PropTypes.string.isRequired,
  fieldvalue: PropTypes.string,
  onChangeEvent: PropTypes.func.isRequired,
};
